import React, { Component } from 'react'
import "./Projects.css"
import { Container } from 'react-bootstrap'

export default class Projects extends Component {
    render() {
        return (
            <>
                <Container className="projects" style={{ minWidth: 300, maxWidth: 512, height: "auto" }}>
                    <p>Projects</p>
                </Container>
            </>
        )
    }
}