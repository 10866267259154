import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Content from "./Components/Content.js"
import Menu from "./Components/Menu.js";

function App() {
  return (
    <>
      <Content />
      <Menu />
    </>
  );
}

export default App;
