import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import Home from "../Pages/Home";
import Projects from "../Pages/Projects";

export default class Content extends Component {
    render() {
        return (
            <>
                <Container className="content mt-3 d-flex justify-content-center">
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/projects" component={Projects} />
                    </Switch>
                </Container>
            </>
        )
    }
}
