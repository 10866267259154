import React, { Component } from 'react'
import "./Home.css"
import { Image, Container, Nav } from 'react-bootstrap'
import styled, { keyframes } from 'styled-components'
import { fadeInLeft } from 'react-animations'

const fadeInLeftAnimation = keyframes`${fadeInLeft}`;

const FadeInContainer = styled(Container)`
  animation: 1s ${fadeInLeftAnimation};
`;

export default class Home extends Component {
    render() {
        return (
            <>
                <FadeInContainer className="home" style={{ minWidth: 300, maxWidth: 512, height: "auto" }}>
                    <div className="text-center">
                        <Image src="/images/face.jpg" className="face mt-2 mb-2" alt="My Face" fluid rounded style={{ width: 512, height: "auto" }} />
                    </div>
                    <blockquote className="blockquote text-center border bg-light">
                        <p className="mb-0"><code>&lt;h1&gt;Hello, world!&lt;/h1&gt;</code></p>
                        <footer className="blockquote-footer">Me, 1995</footer>
                    </blockquote>
                    <Nav className="socialIcons" fill>
                        <Nav.Item as="a" href="https://t.me/chasm47" target="_blank" rel="noopener noreferrer">
                            <Image src="/images/telegram_icon.png" alt="Telegram" style={{ margin: "auto", width: 42, height: 42, border: 0 }} />
                        </Nav.Item>
                        <Nav.Item as="a" href="https://github.com/chasm47" target="_blank" rel="noopener noreferrer">
                            <Image src="/images/github_icon.png" alt="GitHub" style={{ margin: "auto", width: 42, height: 42, border: 0 }} />
                        </Nav.Item>
                        <Nav.Item as="a" href="https://twitter.com/chasm47" target="_blank" rel="noopener noreferrer">
                            <Image src="/images/twitter_icon.png" alt="Twitter" style={{ margin: "auto", width: 42, height: 42, border: 0 }} />
                        </Nav.Item>
                        <Nav.Item as="a" href="https://instagram.com/chasm47" target="_blank" rel="noopener noreferrer">
                            <Image src="/images/instagram_icon.png" alt="Instagram" style={{ margin: "auto", width: 42, height: 42, border: 0 }} />
                        </Nav.Item>
                        <Nav.Item as="a" href="https://vk.com/chasm47" target="_blank" rel="noopener noreferrer">
                            <Image src="/images/vk_icon.png" alt="VK" style={{ margin: "auto", width: 42, height: 42, border: 0 }} />
                        </Nav.Item>
                    </Nav>
                </FadeInContainer>
            </>
        )
    }
}
