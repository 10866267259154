import React, { Component } from 'react'
import { ToggleButtonGroup, ToggleButton, Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import styled, { keyframes } from 'styled-components'
import { bounceInUp } from 'react-animations'

const bounceInUpAnimation = keyframes`${bounceInUp}`;

const BouncyToggleButtonGroup = styled(ToggleButtonGroup)`
  animation: 1s ${bounceInUpAnimation};
`;

export default class Menu extends Component {
    render() {
        return (
            <>
                <div style={{ height: 86 }}></div>
                <Container className="menu d-flex justify-content-center fixed-bottom">
                    <Row>
                        <Col>
                            <BouncyToggleButtonGroup type="radio" name="menuButtons" defaultValue={1} className="m-4" aria-label="Menu-buttons">
                                <ToggleButton value={1} as={Link} to="/" variant="secondary">Home</ToggleButton>
                                <ToggleButton disabled value={2} as={Link} to="/projects" variant="secondary">Projects</ToggleButton>
                            </BouncyToggleButtonGroup>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}
